App.Modules = App.Modules || {};
App.Modules.ExpandHidden = function ($) {

  var expand = function(data)
    {
      var $el = $(data.elem),
        $container = $( $el.attr('data-expand-hidden') );

      $container.find('.hidden').slideDown();
      $el.addClass('hidden');
    };

  return {
    init: function() { return this; },
    events: function() {
      Events.bind('click', '[data-expand-hidden]').to(expand, this);
      return this;
    }
  };
}(jQuery);
