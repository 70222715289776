App.Modules = App.Modules || {};
App.Modules.MobileNavigation = function ($) {

  var $nav =  $('nav.primary'),
    $mobileSearchBox = $('.mobile-search-box'),
    $desktopSearchBox =  $('.js-search .text--search'),
    $searchLabel = $('.js-search .hide-1024'),
    $closeDesktopSearch = $('.js-search .fa-close'),
    $desktopSearchWrapper = $('.js-search .search-wrap'),

    openNav = function(data) {
      $nav.fadeIn(250, function() {
        $('body').css('overflow', 'hidden');
      });
    },

    closeNav = function(data) {
      $nav.fadeOut(250, function() {
        $('body').css('overflow', '');
      });
    },

    forceShow = function(data)
    {
      $nav.css('display', 'block');
      $('body').css('overflow', '');
    },

    forceHide = function(data)
    {
      $('nav.primary').css('display', 'none');

      if( ! $(document.activeElement).attr('type') === 'text' ) {
        $('.mobile-search-box').css('display', 'none');
        $('.js-search .text--search').css('display', 'none');
        $('.js-search .fa-close').css('display', 'none');
      }

      $('body').css('overflow', '');
    },

    toggleSearch = function(data)
    {
      if( $(window).width() >= 768 ) {
        $desktopSearchBox.focus();
        $searchLabel.fadeOut(250, function() {

          if( $(window).width() >= 768 && $(window).width() <= 1024 ) {
            $desktopSearchWrapper.addClass('js-active-search').animate({'right':'150px'});
          }
          if( $(window).width() >= 1024 ) {
            $desktopSearchWrapper.addClass('js-active-search').animate({'right':'175px'});
          }

          $desktopSearchBox.fadeIn();
          $closeDesktopSearch.fadeIn();
        });
      } else {
        $mobileSearchBox.slideToggle();
        $mobileSearchBox.find('.text--search').focus();
      }
    },

    closeDesktopSearch = function(data)
    {
      $desktopSearchBox.fadeOut(250, function() {
        $closeDesktopSearch.fadeOut(100, function() {
          $desktopSearchWrapper.animate({'right':'51px'}, function() {
            if( $(window).width() >= 1024 ) {
              $searchLabel.fadeIn();
            }
            $desktopSearchWrapper.removeClass('js-active-search');
          });
        });
      });
    },

    menuResize = function(data)
    {
        if( $(window).width() >= 768 ) {
          forceShow(data);
        } else {
          forceHide(data);
        }
    };

  return {
    init: function() { return this; },
    events: function() {
      Events.bind("click", ".js-mobile-menu-open").withoutBubble().to(openNav, this);
      Events.bind("click", ".js-mobile-menu-close").withoutBubble().to(closeNav, this);
      Events.bind("click", ".js-search .search-wrap").withoutBubble().to(toggleSearch, this);
      Events.bind("click", ".js-close-search").withoutBubble().to(closeDesktopSearch, this);
      Events.bind("resize").to(Utils.debounce(menuResize, 25), this);

      return this;
    }
  };
}(jQuery);
