App.Modules = App.Modules || {};
App.Modules.DataExpand = function () {

    var toggleExpand = function(data) {
      var $el = $(data.elem);

      $el.toggleClass('collapsed');
      $("[data-expand-name='" + $el.attr('data-expand-target') + "']").slideToggle();
    };

    return {
        init: function() { return this; },
        events: function() {
            Events.bind("click", "[data-expand-target]").to(toggleExpand, this);
            Events.subscribe('rebindSectionExpand', function(data) {
              Events.bind("click", "[data-expand-target]").to(toggleExpand, this);
            });
            return this;
        }
    };
}();

