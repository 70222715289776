App.Modules = App.Modules || {};
App.Modules.ImageLoader = function ($) {

  var defaultImage = "/assets/images/default-image.png";
  var loadImages = function() {
    $('[data-image]').each(function() {
      var $el = $(this),
        img = new Image();

      img.onload = function() {
        $el.attr('src', img.src);
        $el.css({width: 'auto', 'flex-shrink': 0});
      };

      img.onerror = function() {
        $el.attr('src', defaultImage);
        $el.css({width: 'auto', 'flex-shrink': 0});
      };

      img.src = $el.attr('data-image');
    });
  };

  return {
    init: function() {
      loadImages();
      return this;
    },
    events: function() {
      Events.subscribe('loadImages', loadImages);
      return this;
    }
  };

}(jQuery);
