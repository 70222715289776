App.Modules = App.Modules || {};
App.Modules.ProductFilters = function () {

  var filters = {},
    $viewContainer = $('.product-listing'),

  toggleCategory = function(data)
  {
   $(data.target).nextAll('ul').first().find('.more-filters').hide().prev().show();
   $(data.target).toggleClass('open').nextAll('ul').first().slideToggle();
  },

  toggleFilter = function(data)
  {
    var $el = $(data.elem),
      filterKey = $el.attr('data-filter-key'),
      filterVal = $el.attr('data-filter-value');

   $('[data-filter-value="' + filterVal.replace(/"/g, '\\"') + '"]').toggleClass('checked');

    if( filters[filterKey] )
    {
      if( $el.hasClass('checked') ) { // Adding a filter
        filters[filterKey].push(filterVal);
      } else { //removing a filter
        filters[filterKey].splice(filters[filterKey].indexOf(filterVal), 1);
        if( ! filters[filterKey].length )
        {
          delete filters[filterKey];
        }
      }
    } else {
      filters[filterKey] = [filterVal];
    }

    if( $el.parent().hasClass('more-filters') )
    {
      $el.parent().hide();
      $el.parent().prev().show();
    }

    //fetch filtered products
    $('html, body').animate({
      scrollTop: $viewContainer.offset().top - $('header').outerHeight(true) - 25
    });

    $viewContainer.fadeOut(400, getFiltered);
    setQueryString(filters);

  },

  toggleInputFilter = function(data)
  {
    var $el1 = $(data.elem).parent().find('input').first(),
      filterKey1 = $el1.attr('name'),
      filterVal1 = $el1.val(),
      $el2 = $el1.next().next(),
      filterKey2 = $el2.attr('name'),
      filterVal2 = $el2.val();

    if( filterVal1 == '' && filterVal2 == '')
    {
      //intentionally blank
    } else {

      if (isNaN(filterVal1) || ( filterVal1 == '' )) {
        alert('Please enter a valid number.');
        $el1.val('');

        var t = setTimeout(function () {
          $el1.focus();
        }, 10);
        return false;
      }

      if (isNaN(filterVal2) || ( filterVal2 == '' )) {
        alert('Please enter a valid number.');
        $el2.val('');

        var t = setTimeout(function () {
          $el2.focus();
        }, 10);
        return false;
      }

      //check that max is greater than min
      if (parseFloat(filterVal1) > parseFloat(filterVal2)) {
        alert('Please enter a minimum value that is smaller than the maximum value.');
        $el1.val('');
        $el2.val('');
        var t = setTimeout(function () {
          $el1.focus();
        }, 10);
        return false;
      }
    }

    if( ! filterVal1 )
    {
      if( ! filters[filterKey1] )
      {
        return false;
      } else {
        delete filters[filterKey1];
      }
    } else {
      filters[filterKey1] = [filterVal1];
    }

    if( ! filterVal2 )
    {
      if( ! filters[filterKey2] )
      {
        return false;
      } else {
        delete filters[filterKey2];
      }
    } else {
      filters[filterKey2] = [filterVal2];
    }

    //fetch filtered products
    $('html, body').animate({
      scrollTop: $viewContainer.offset().top - $('header').outerHeight(true) - 25
    });

    $viewContainer.fadeOut(400, getFiltered);
    setQueryString(filters);
  },

  showMoreFilters = function(data)
  {
    $(data.elem).hide().next().slideDown();
  },

  hideMoreFilters = function(data)
  {
    var $el = $(data.elem);
    $el.parent().hide();
    $el.parent().prev().show();
  },

  toggleFilterList = function(data)
  {
    $('.product-filters').slideToggle();
  },

  getFiltered = function() {
    var type = $viewContainer.attr('data-type');
    $.ajax({
      url: '/getFiltered' + (type ? '/' + type : ''),
      headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
      },
      data: {filters: filters},
      type: 'POST',
      success: function(data)
      {
        $viewContainer.html(data);
        Events.publish('rebindCompareClick', {});
        Events.publish('loadImages', {});
        Events.publish('rebindSectionExpand', {});
        $viewContainer.delay(150).fadeIn();
        window.setCompareCheckBoxes();
      },
      error: function(data)
      {
        $viewContainer.fadeIn();
        alert('Oops. Something went wrong... please wait a few moments and try again.')
      },
      complete: function(data)
      {

      }
    });
  },

  setQueryString = function(obj)
  {
    var type = $viewContainer.attr('data-type');

    if(obj) {
      var q = '?',
        keys = Object.keys(obj);

      keys.forEach(function(key) {
        q += key + '=' + obj[key].join(',') + '&';
      });


      if (q !== '?') {
        q = q.substr(0, q.length - 1);
        history.replaceState({}, '', '/' + (type ? type : '')  + q);
      } else {
        history.replaceState({}, '', '/' + (type ? type : ''));
      }
    }
  },

  setupFilters = function()
  {
    if( location.search ) {
      var queryString = location.search.substring(1),
        rawFilters = queryString.split('&');

      rawFilters.forEach(function (filter) {
        var filterParts = filter.split('=');
        filters[filterParts[0]] = filterParts[1].split(',');
      });
    }
  },

  clearAllFilters = function()
  {
    filters = {};
    $('.js-filter').removeClass('checked');
    $('.js-input-filter-apply').each( function() {
      $(this).parent().find('input').val('');
    });

    $('html, body').animate({
      scrollTop: $viewContainer.offset().top - $('header').outerHeight(true) - 25
    });

    $('input[name="keyword"]').val('');
    $viewContainer.fadeOut(400, getFiltered);
    setQueryString(filters);
  },

  clearInputFilter = function(data)
  {
    $(data.elem).parent().find('input').each( function() {
      var $el = $(this);
      $el.val('');
      delete filters[ $el.attr('name') ];
    });

    $('html, body').animate({
      scrollTop: $viewContainer.offset().top - $('header').outerHeight(true) - 25
    });

    $viewContainer.fadeOut(400, getFiltered);
    setQueryString(filters);
  };

  return {
    init: function() {
      setupFilters();
      return this;
    },
    events: function() {
      Events.bind("click", ".js-filter-category").to(toggleCategory, this);
      Events.bind("click", ".js-filter").to(toggleFilter, this);
      Events.bind("click", ".js-input-filter-apply").withoutBubble().to(toggleInputFilter, this);
      Events.bind('click', '.js-more-filters').to(showMoreFilters, this);
      Events.bind('click', '.js-close-filters').to(hideMoreFilters, this);
      Events.bind('click', '.js-clear-filters').withoutBubble().to(clearAllFilters, this);
      Events.bind('click', '.js-input-filter-clear').withoutBubble().to(clearInputFilter, this);
      Events.bind('click', '.filters-bar').to(toggleFilterList, this);
      Events.subscribe('emptyCompare', function(data) {
        $('html, body').animate({
          scrollTop: $viewContainer.offset().top - $('header').outerHeight(true) - 25
        });

        $viewContainer.fadeOut(400, getFiltered);
      });
      return this;
    }
  };
}();
