"use strict";

(function() {
    var App = {};
    window.App = App;

    document.addEventListener('DOMContentLoaded', function(e) {

        window.compareList = sessionStorage.getItem('compareList') || '';
        window.compareArray = compareList ? compareList.split(',') : [];

        window.setCompareCheckBoxes = function() {
            compareArray.forEach(function(id) {
                $(".product-compare[data-compare-id='" + id + "']").find('i').addClass('fa-check-square-o').removeClass('fa-square-o');
            });
        };


        for (var module in App.Modules) {
            App.Modules[module].init().events();
        }
    });
})();
