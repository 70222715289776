App.Modules = App.Modules || {};
App.Modules.ProductCompare = function () {

  var $viewContainer = $('.product-listing'),

  toggleCompare = function(data) {
    var $checkboxContainer = $(data.elem),
      $checkbox =  $checkboxContainer.find('i'),
      isChecked = $checkbox.hasClass('fa-check-square-o'),
      productId = $checkboxContainer.attr('data-compare-id');

    if( ! isChecked ) { //User attempting to add to compare list
      if( window.compareArray.length == 4) {
        alert("Sorry, you can only compare up to 4 products. Un-check some or clear your comparison queue first.");
        return false;
      }

      //add to list
      window.compareArray.push(productId);
    } else { //User is un-checking something
      window.compareArray.splice(window.compareArray.indexOf(productId), 1);
    }

    $checkbox.toggleClass('fa-square-o fa-check-square-o');

    saveData();
  },

  removeItemFromView = function(data)
  {
    var productId = $(data.elem).attr('data-compare-id');
    $('[data-product-id="' + productId + '"]').fadeOut();
    window.compareArray.splice(window.compareArray.indexOf(productId), 1);
    saveData();

    //check if no more items
    if( ! window.compareArray.length )
    {
      Events.publish('emptyCompare', {});
    }
  },

  saveData = function()
  {
    window.compareList = window.compareArray.join(',');
    sessionStorage.setItem('compareList', compareList);

    //save on server
    $.ajax({
      url: '/compareList',
      type: 'POST',
      headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
      },
      data: {productIdList: compareList},
      success: function(data)
      {
        Events.publish('compareChange', {});
      }

    });
  },

  clearCompare = function(data)
  {
    sessionStorage.clear();
    window.compareList = '';
    window.compareArray = [];
    $(".product-compare[data-compare-id]").find('i').addClass('fa-square-o').removeClass('fa-check-square-o');
    Events.publish('compareChange', {});
  },

  viewCompare = function(data)
  {
    $('html, body').animate({
      scrollTop: $viewContainer.offset().top - $('header').outerHeight(true) - 25
    });

    $viewContainer.fadeOut(400, function() {
      $.ajax({
        url: '/compare',
        headers: {
          'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        type: 'GET',
        success: function(data)
        {
          $viewContainer.html(data);
          $viewContainer.delay(150).fadeIn();
          Events.publish('rebindCompareClick', {});
        },
        error: function(data)
        {
          $viewContainer.fadeIn();
          alert('Oops. Something went wrong... please wait a few moments and try again.')
        },
        complete: function(data)
        {

        }
      });
    });
  },

  compareChange = function(data)
  {
    var numProds = window.compareArray.length;
    $('.js-compare-number').html( numProds );

    if(numProds == 0)
    {
      $('.product-compare-box').fadeOut();

      if( $('.product-comparison').length )
      {
        Events.publish('emptyCompare', {});
      }
    } else {
      $('.product-compare-box').fadeIn();
    }
  };

  return {
    init: function() {
      window.setCompareCheckBoxes();
      compareChange();
      return this;
    },
    events: function() {
      Events.bind("click", ".product-compare").to(toggleCompare, this);
      Events.bind("click", '.js-clear-compare').withoutBubble().to(clearCompare, this);
      Events.bind("click", '.js-view-compare').withoutBubble().to(viewCompare, this);
      Events.bind("click", '.js-remove-compare').withoutBubble().to(removeItemFromView, this);
      Events.subscribe('compareChange', function(data) { compareChange(data) });
      Events.subscribe('rebindCompareClick', function(data) {
        Events.bind("click", ".product-compare").to(toggleCompare, this);
        Events.bind("click", '.js-remove-compare').withoutBubble().to(removeItemFromView, this);
      });
      return this;
    }
  };
}();
