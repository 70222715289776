App.Modules = App.Modules || {};
App.Modules.SwitchProdImages = function ($) {

  var switchProdImage = function(data) {
      var $el = $(data.elem),
        newImg = $el.find('img').attr('src');

    $('#main-img').attr('src', newImg).show();
    $('#main-video').parent().hide();
  };

  var switchProdVideo = function(data) {
    var $el = $(data.elem),
      newVid = $el.find('img').attr('data-video-src');

    $('#main-img').hide();
    $('#main-video').attr('src', newVid).parent().show();
    $('#main-video')[0].play();
  };

  return {
    init: function() { return this; },
    events: function() {
      Events.bind('click', '[data-switch-img]').withoutBubble().to(switchProdImage, this);
      Events.bind('click', '[data-switch-video]').withoutBubble().to(switchProdVideo, this);
      return this;
    }
  };
}(jQuery);
