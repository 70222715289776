App.Modules = App.Modules || {};
App.Modules.iosFix = function () {

  var openSearch = function(data) {
    jQuery('meta[name=viewport]').remove();
    jQuery('head').append('<meta name="viewport" content="width=device-width, maximum-scale=1.0, user-scalable=0">');
  };

  var closeSearch = function(data) {
    $('meta[name=viewport]').remove();
    $('head').append('<meta name="viewport" content="width=device-width, initial-scale=yes">' );
  };

  return {
    init: function() {
      return this;
    },
    events: function() {
      Events.bind("focus", ".q").to(openSearch, this);
      Events.bind("focusout", ".q").to(closeSearch, this);
      return this;
    }
  };
}();
